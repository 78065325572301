<template>
  <v-container fluid class="pa-6">
    <v-row no-gutters align="center"
      ><v-col cols="12" md="6" align="start"
        ><h2>
          <v-icon color="primary" large left>{{ icon }}</v-icon
          >{{ title }} <span v-if="totalCount">({{ totalCount }})</span>
        </h2> </v-col
      ><v-col cols="12" md="6" align="end">
        <v-btn
          v-if="userCan('generate-report')"
          class="primary--text  mx-2"
          :disabled="loading"
          @click="generateReport()"
          >Generate <v-icon right color="primary">mdi-file-chart</v-icon>
        </v-btn>
      </v-col></v-row
    >
    <v-data-table
      :items="items"
      :loading="loading"
      :headers="names"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions
      }"
    >
      <template v-slot:item.status="{ item }">
        <span v-if="item.status === 'success'" class="primary--text">{{
          item.status
        }}</span>
        <span v-else-if="item.status === 'error'" class="error--text"
          >{{ item.status }} - {{ item.error_message }}</span
        >
        <span v-else class="warning--text">Pending</span>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ formatDate(item.created_at, "DD.MM.YYYY") }}
      </template>
      <template v-slot:item.actions="{ item }">
        <span v-if="item.file_url">
          <v-btn
            text
            class="primary--text  mx-2"
            :link="true"
            target="_blank"
            :href="item.download_report_url"
            >Download report
            <v-icon right color="primary">mdi-cloud-download</v-icon>
          </v-btn></span
        >
        <span v-else class="info--text"
          >Report is being generated
          <v-progress-circular indeterminate color="info"
        /></span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="error--text"
              large
              v-bind="attrs"
              v-on="on"
              v-if="userCan('delete-' + [routeName])"
              @click="
                confirmDeleteDialog = true;
                currentId = item.id;
              "
            >
              <v-icon color="error">mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="310">
      <v-card>
        <v-card-title class="error headline white--text"
          >Confirm Delete</v-card-title
        >
        <v-card-text class="body-1 pa-3"
          >Are you sure you want to delete this item?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            large
            @click.native="confirmDeleteDialog = false"
          >
            <v-icon size="20" left>mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-btn color="error" outlined large @click.native="deleteItem">
            <v-icon size="20" left>mdi-trash-can-outline</v-icon>Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import date from "@/mixins/date";
import file from "@/mixins/file";
import permission from "@/mixins/permission";
export default {
  mixins: [date, file, permission],
  name: "ReportList",
  props: {
    routeName: {
      type: String,
      required: true
    },
    namespace: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true,
      default: "Title"
    },
    icon: {
      type: String,
      required: true,
      default: "mdi-help"
    },
    names: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      confirmDeleteDialog: false,
      currentId: null,
      timeout: 0,
      itemsPerPageOptions: [12, 24, 48, -1]
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.getters[this.namespace + "/loading"];
      }
    },
    totalCount: {
      get() {
        return this.$store.getters[this.namespace + "/total"];
      }
    },
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/getItems");
      }
    }
  },
  created() {
    this.getData();
  },
  watch: {
    items: {
      handler: function(val, oldVal) {
        if (val.length === 0) {
          return;
        }
        if (!val[0].file_url) {
          setTimeout(this.getData, 5000);
        }
      },
      deep: true
    }
  },
  methods: {
    deleteItem() {
      const _this = this;
      _this.$store
        .dispatch(_this.namespace + "/deleteItem", this.currentId)
        .then(() => {
          this.confirmDeleteDialog = false;
          this.currentId = null;
          this.getData();
        });
    },
    async generateReport() {
      await this.$store
        .dispatch(this.namespace + "/generateReport")
        .then(() => {
          this.getData();
        });
    },
    getData() {
      this.$store.dispatch(this.namespace + "/getItems");
    }
  }
};
</script>
